<template>
  <div class="daily-report">
    <div class="page-header">
      <h1>{{ $t('Report') }} > {{$t('Product Report') }} </h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Top 10 Best Product Seller') }}</h2>
        <a :href="apiUrl + '/report/product/best-seller/export'">
          <el-button class="action-button" type="primary">{{ $t('Export') }}</el-button>
        </a>
      </div>
      <ItemFilter :placeholder="$t('Search by...')" default="date" @search="searchItems">
        <el-option :label="$t('Search by date')" value="date"></el-option>
        <el-option :label="$t('Search by customer ID')" value="customerId"></el-option>
        <el-option :label="$t('Search by type')" value="type"></el-option>
        <el-option :label="$t('Search by brand')" value="brand"></el-option>
        <el-option :label="$t('Search by IMEI')" value="IMEI"></el-option>
      </ItemFilter>
      <BestSeller />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BestSeller from '@/components/report/BestSellerProduct.vue';
import ItemFilter from '@/components/Filter.vue';

export default {
  name: 'ProductReport',
  components: {
    BestSeller,
    ItemFilter,
  },
  data(){
    return {
      activeName: 'Total Sales',
    };
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.daily-report{
  .page-content .content-tabs .el-tabs .el-tabs__content{
    display: block;
    padding-left: 25px;
  }
  .top-sales-wrapper{
    margin-top: 50px;
    width: 100%;
    padding-left: 20px;
  }
  .best-seller-list{
    padding-left: 20px;
    margin-top: 30px;
  }
}
</style>
